@import '_palette.scss';

.header{
  background: #fff;
  height: 7.2rem;
  width: 100%;
  position: sticky;
  top:0;
  left: 0;
  right: 0;
  z-index: 99;
  & *{
    font-family: 'notosans';
  }
  &__wrapper{
    width: 100%;
    height: 100%;
    padding: 0 4rem;
    box-shadow: 0 .1rem 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
  }
  &__content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &__col{
      display: flex;
      align-items: center;
    }
  }
  &__logo{
    max-width: 20rem;
    width: 100%;
    height: max-content;
    & *{
      width: 100%;
    }
  }
  &__nav{
    display: flex;
    height: max-content;
    align-items: center;
    margin: 0 4.8rem;
    &__menu{
      height: max-content;
      &__btn{
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 5rem;
        &>*{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &>ul{
        display: flex;
        list-style-type: none;
        padding: 0;
        &>li{
          &:not(:last-child){
            margin-right: 4.8rem;
          }
          & *{
            text-decoration: none;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 140%;
            color: $primary;
            transition: text-shadow .15s linear;
            &:hover{
              text-shadow: 0 0 .09rem #a0c42c, 0 0 .1rem #a0c42c;
            }
          }
        }
      }
    }
  }
  &__buttons{
    display: flex;
    &__button{
      &:not(:last-child){
        margin-right: 2.4rem;
      }
    }
  }
  &__menu{
    display: none;
    align-items: center;
    &>*{
      max-height: 2.4rem;
      max-width: 2.4rem;
    }
  }
}

.mob-menu{
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  top:0;
  right: -100%;
  bottom: 0;
  transition: right .3s linear, background-color .3s linear;
  transition-delay: 0s, .3s;
  z-index: 99;
  max-width: 100vw;
  max-height: 100vh;
  height: 100%;
  &.open{
    background: #0006;
    right: 0;
  }
  &__inner{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
  &__close{
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }
  &__nav{
    display: flex;
    flex-direction: column;
    &__list{
      &>ul{
        list-style-type: none;
        text-align: center;
        padding: 0;
        &>li{
          &>* {
            font-weight: 600;
            font-size: 2rem;
            line-height: 140%;
            opacity: 0.8;
            color: $primary;
          }
          &:not(:last-child){
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    &__wrapper {
      padding: 0 16px;
    }
    &__nav {
      margin: 0 1.8rem;
      &__menu > ul > li:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
}

@media (max-width: 666px) {
  .header{
    &__nav, &__buttons{
      display: none;
    }
    &__logo{
      max-width: 10rem;
      display: flex;
      align-items: center;
    }
    &__menu{
      display: flex;
    }
  }
}