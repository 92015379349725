@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/base/_01_normalize.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/base/_mixins.scss";

@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/global/_fonts.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/global/_global.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/global/_svg.scss";

@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/_local-fonts.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/_palette.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/base/_01_normalize.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/base/_mixins.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/global/_fonts.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/global/_global.scss";
@import "/Users/admin/Documents/MishaOmelyanchuk/physiotherapy/src/scss/global/_svg.scss";

@import '_palette.scss';

body{
  overflow-x: hidden;
}

.main{
  overflow: hidden;
}


.wrapper{
  max-width: 153rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
}

.button{
  border-radius: 1.2rem;
  max-width: 29rem;
  width: 100%;
  height: 8.8rem;
  transition: background-color .3s linear;
  &>*{
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: none;
    border: 0;
    transition: color .3s linear;
    font-weight: 400;
    font-size: 2rem;
    line-height: 140%;
    white-space: nowrap;
    & svg, & img{
      width: 2.3rem;
      height: 2.3rem;
      margin-right: 1.5rem;
    }
    &>*{
      transition: color .3s linear;
      font-weight: 400;
      font-size: 2rem;
      line-height: 140%;
      white-space: nowrap;
    }
    & path,{
      transition: fill .3s linear, stroke .3s linear;
    }
  }

  &_header{
    max-width: max-content;
    height: 4.6rem;
    border-radius: .4rem;
    &>*{
      padding: 0 1.4rem;
      &>*{
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
    & svg, & img{
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }
  &_green{
    background-color: $primary;
    &>* {
      color: #fff;
      & > * {
        color: #fff;
      }
    }
    & svg>path{
      stroke: #fff;
    }
    &:hover{
      background: $primary-hover;
    }
  }
  &_white{
    background: $white-btn;
    border: .1rem solid $primary;
    &>*>*{
      color: $primary;
    }
    & svg>path{
      stroke: $primary;
    }
    &:hover{
      background-color: $white-btn-hover;
    }
  }
  &_orange{
    background: #fff;
    border: .1rem solid $secondary;
    &.button_active{
      background-color: $secondary-hover;
    }
    &>*>*{
      color: $secondary;
    }
    & svg>path{
      stroke: $secondary;
    }
    &:hover{
      background-color: $secondary-hover;
    }
  }
  &_active{
    background-color: $primary;
    &>* {
      color: #fff;
      & > * {
        color: #fff;
      }
    }
    & svg>path{
      stroke: #fff;
    }
    &:hover{
      background-color: $primary;
      &>* {
        color: #fff;
        & > * {
          color: #fff;
        }
      }
      & svg>path{
        stroke: #fff;
      }
    }
  }
}

.section{
  &_promo{
    position: relative;
  }
  &__title{
    margin-bottom: 4rem;
    &>*{
      font-weight: 600;
      font-size: 6rem;
      line-height: 140%;
      color: $primary;
      opacity: 0.8;
    }
  }
  &__link{
    display: flex;
    &>*{
      font-size: 2rem;
      line-height: 140%;
      color: $primary;
    }
    & svg{
      margin-left: 1.4rem;
    }
  }
  &__promo{
    &__mask{
      position: absolute;
      top:0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.58);
    }
    &__content{
      min-height: 62.8rem;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__inner{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title{
      &>* {
        text-transform: none;
        font-weight: 600;
        font-size: 6.4rem;
        line-height: 140%;
        color: $primary;
      }
    }
  }
  &__two-blocks{
    padding-top: 10rem;
    padding-bottom: 6.5rem;
    display: flex;
    flex-wrap: wrap;
    &.reverse{
      flex-direction: row-reverse;
      & .section__two-blocks__col:first-child{
        display: flex;
        justify-content: flex-end;
      }
    }
    &__col{
      width: 50%;
    }
    &__inner{
      max-width: 60rem;
      padding-right: 2rem;
    }
    &__subtitle{
      margin-bottom: 3.6rem;
      &>* {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 160%;
      }
    }
    &__text{
      margin-bottom: 9rem;
      &>*{
        font-size: 2rem;
        line-height: 160%;
        color: $dark;
        &:not(:last-child){
          margin-bottom: 4rem;
        }
      }
    }
    &__img{
      background: #EBEBEB;
      border-radius: 1.2rem;
      overflow: hidden;
      width: max-content;
      &__wrap{
        display: flex;
        width: 100%;
        justify-content: center;
      }
      & img{
        width: 100%;
      }
      &.vertical{
        width: max-content;
        max-height: 75rem;
        & img {
          width: unset;
          height: 100%;
        }
      }
    }
  }
}

.home{
  background-image: url("../img/promo/index/home_bg.png");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  .section{
    &__promo{
      &__inner{
        padding: 220px 0;
        max-width: 92.2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__title{
        margin-bottom: 4.8rem;
        text-align: center;
        &>*{
          font-weight: 600;
          font-size: 6.4rem;
          line-height: 140%;
          color: $primary;
          text-transform: none;
        }
      }
      &__subtitle{
        text-align: center;
        margin-bottom: 4.8rem;
        &>*{
          font-size: 32px;
          line-height: 160%;
          color: $darker;
          font-weight: normal;
        }
      }
      &__btns{
        display: flex;
        &__btn{
          width: 29rem;
          &>*{
            border: none;
            filter: drop-shadow(0 .4rem .6rem rgba(0, 0, 0, 0.15));
          }
          & button, a>*{
            font-family: notosans;
            font-weight: 600;
          }
          &:first-child{
            margin-right: 2rem;
          }
        }
      }
    }
  }
}

.my-services{
  overflow: hidden;
  padding-top: 11.2rem;
  background-image: url("../img/my-services_bg1.png"), url("../img/my-services_bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: 0, 37.6rem;
  .slick-slide:not(:last-child) .my-services__slider__slide{
    padding-right: 2rem;
  }
  .slick-track{
    display: flex;
    overflow: visible;
  }
  .slick-list{
    overflow: visible;
  }
  .slick-slide{
    height: unset!important;
    max-width: 45.5rem!important;
    width: 100%;
  }
  &__popup{
    font-size: 2rem;
    padding: 0 2rem;
  }
  &__slider{
    padding-top: .8rem;
    margin-bottom: 14.8rem;
    &__wrap{
      position: relative;
    }
    &__shadow{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 31.8rem;
      background: linear-gradient(269.4deg,#fff 30.97%,rgba(255,255,255,0) 122.67%);
      z-index: 2;
      left: calc(100% + ((100vw - 100%) / 2));
      transform: translateX(-100%);
    }
    &__nav-left, &__nav-right{
      position: absolute;
      transition: opacity .3s linear;
      opacity: 0;
      top:50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 8.8rem;
      height: 8.8rem;
      border-radius: 50%;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active{
        opacity: 1;
      }
    }
    &__nav-left{
      left: 0;
      &>*{
        transform: rotate(-180deg);
      }
    }
    &__nav-right{
      right: 0;
    }
    &__slide{
      height: 100%;
      &__inner{
        padding: 2.8rem;
        background: #ffffff;
        box-shadow: 0 .4rem .8rem rgba(0, 0, 0, 0.25);
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
    &__title{
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      &>*{
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 140%;
        color: $darker;
      }
      & svg, & img {
        transition: transform .3s linear;
        display: none;
      }
    }
    &__desc{
      margin-bottom: 6rem;
      &>*{
        font-size: 1.6rem;
        line-height: 160%;
        color: $dark;
      }
    }

    .section__link{
      justify-content: flex-end;
    }
  }
  &__form{
    display: flex;
    justify-content: center;
    background: #E9F6EC;
    & form{
      max-width: 60rem;
      width: 100%;
      margin-top: 3.5rem;
    }
    &__row:not(:last-child){
      margin-bottom: 4rem;
    }
    &__inner{
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 65rem;
      width: 100%;
    }
    .section__title{
      text-align: center;
    }
    &__subtitle {
      &>* {
        font-size: 1.6rem;
        line-height: 160%;
        text-align: center;
      }
    }
    &__btn{
      display: flex;
      justify-content: center;
      & button{
        font-weight: 600;
        font-size: 2.8rem;
      }
    }
  }
}

.input{
  &__text{
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    & label{
      font-weight: 500;
      font-size: 2rem;
      line-height: 140%;
      margin-bottom: 1.2rem;
    }
    & input{
      font-weight: 400;
      font-size: 2rem;
      line-height: 140%;
      padding: 0 2rem;
      height: 8.8rem;
      background: #FFFFFF;
      border: .1rem solid #C5CABA;
      box-sizing: border-box;
      border-radius: 1.2rem;
      outline: none;
      &.error{
        border: .1rem solid $error;
      }
    }
  }
  &__textarea{
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    & label{
      font-weight: 500;
      font-size: 2rem;
      line-height: 140%;
      margin-bottom: 1.2rem;
    }
    & textarea{
      font-weight: 400;
      font-size: 2rem;
      line-height: 140%;
      padding: 2rem;
      height: 8.8rem;
      background: #FFFFFF;
      border: .1rem solid #C5CABA;
      box-sizing: border-box;
      border-radius: 1.2rem;
      min-width: 100%;
      max-width: 60rem;
      min-height: 8.8rem;
      max-height: 20rem;
      &.error{
        border: .1rem solid $error;
      }
    }
  }
}

section:last-child{
  padding-bottom: 13rem;
}

.footer{
  background-image: url("../img/footer_bg.png");
  padding-top: 17.5rem;
  background-position: top;
  background-size: cover;
  margin-top: -15rem;
  &__wrapper{
    padding: 7rem 16px;
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1rem;
  }
  &__block{
    max-width: calc(100% / 5 - 2rem);
    width: max-content;
    margin: 0 1rem;
    &__title{
      margin-bottom: 2rem;
      &>* {
        font-weight: bold;
        font-size: 2rem;
        line-height: 160%;
      }
    }
    &__text{
      &>*{
        font-size: 2rem;
        line-height: 160%;
        font-weight: 400;
      }
    }
  }
  &__logo{
    max-width: 18rem;
    & img{
      width: 100%;
    }
  }
  &__socials{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    a {
      width: max-content;
      &:first-child{
        margin-right: 2rem;
      }
    }
    &__item{
      width: 5.6rem;
      height: 5.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      border-radius: 50%;
    }
  }
  &_text{

    &-block{
      & .footer__block__text{
        margin-top: 5rem;
        &>*{
          font-size: 1.4rem;
          line-height: 160%;
        }
      }
    }
  }
  &_text-double{
    display: flex;
    flex-direction: column;
    &__col{
      display: flex;
      &>*{
        &:first-child{
          margin-right: 2rem;
        }
        &:last-child{
          text-align: right;
          width: 100%;
        }
      }
    }
  }
  &__copyright{
    display: none;
  }
  &_logo-mobile{
    display: none;
  }
}

.error{
  & label{
    color: $error;
    display: flex;
    align-items: center;
  }
  & svg, & img{
    margin-right: 1rem;
  }
}

.errorText{
  font-size: 1.4rem!important;
  color: $error!important;
  margin-top: 1rem;
}

.about-promo{
  background-image: url("../img/promo/about/about_bg.png");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
}

.about{
  position: relative;
  overflow: hidden;
  &__elem{
    &-1, &-2{
      position: absolute;
      max-width: 50%;
      & img{
        width: 100%;
      }
    }
    &-1{
      right: 0;
      top: 32rem;
    }
    &-2{
      bottom: 0;
      left: 0;
    }
  }
}

.training{
  background-image: url("../img/training_bg.png");
  background-position: top;
  background-size: cover;
  padding-top: 38.3rem;
  margin-top: -20rem;
  &__wrap{
    display: flex;
    justify-content: center;
  }
  &__inner{
    max-width: 60.5rem;
    & .section__title{
      margin-bottom: 8rem;
      &>*{
        color: #2D3E0A;
      }
    }
  }
  &__list{
    position: relative;
    &__line{
      position: absolute;
      top: .6rem;
      bottom: 0;
      width: .2rem;
      left: -2.4rem;
      transform: translateX(-226%);
      background: #2d3e0a;
    }
    & ul{
      padding: 0;
      position: relative;
      list-style-type: none;
    }
    & li{
      position: relative;
      &:not(:last-child){
        margin-bottom: 4rem;
      }
      &::before{
        content: "";
        position: absolute;
        width: .8rem;
        height: .8rem;
        background: #2d3e0a;
        border-radius: 50%;
        top: .6rem;
        left: -2.4rem;
        transform: translateX(-100%);
      }
    }
    &__head{
      margin-bottom: 1rem;
      &>*{
        font-weight: bold;
        font-size: 2rem;
        line-height: 160%;
      }
    }
    &__desc{
      &>*{
        font-size: 2rem;
        line-height: 160%;
        font-weight: 300;
        &:not(:last-child){
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.services-promo{
  background-image: url("../img/promo/services/services_promo.png");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.services{
  position: relative;
  padding-bottom: 32rem!important;
  padding-top: 8rem;
  &__bg{
    position: absolute;
    bottom: -.5rem;
    left: 0;
    right: 0;
    z-index: -1;
    &>*{
      width: 100%;
    }
  }
  &__nav{
    display: flex;
    margin: 0 auto 7.8rem;
    overflow-x: scroll;
    justify-content: flex-start;
    max-width: max-content;
    width: 100%;
    &__inner{
      display: flex;
      width: max-content;
      & a{
        padding: 0 2rem;
      }
      & .button{
        height: 7.8rem;
        &:not(:last-child){
          margin-right: 1.2rem;
        }
      }
    }
  }
  &__tab{
    display: none;
    &.active{
      display: block;
    }
  }
  &__service{
    padding: 6.4rem 0;
    &:not(:last-child){
      border-bottom: .1rem solid #D0D0D0;
    }
    &__inner{
      display: flex;
    }
    &__content{
      width: 60%;
    }
    &__img{
      width: 40%;
      display: flex;
      justify-content: flex-end;
      padding-top: 5rem;
      &__inner{
        width: 44.5rem;
        height: 42.2rem;
        border-radius: 1.2rem;
        overflow: hidden;
        &>*{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__title {
      margin-bottom: 6.4rem;
      & > * {
        font-weight: 600;
        font-size: 4.8rem;
        line-height: 140%;
        color: #303030;
      }
    }
    &__subtitle{
      color: $primary;
      font-size: 2.8rem;
      margin-top: 6.8rem;
      line-height: 160%;
    }
    &__text{
      &>*{
        font-size: 2rem;
        line-height: 160%;
        font-weight: 300;
        &:not(:last-of-type){
          margin-bottom: 3rem;
        }
      }
      & a{
        text-decoration: underline;
      }
      & ol, & ul{
        padding-left: 2rem;
      }
      & li{
        padding-left: 1rem;
      }
    }
    &__tags{
      display: flex;
      &>*:not(:last-child){
        margin-right: 1.5rem;
      }
    }
    &__price{
      height: 8.5rem;
      width: max-content;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $price;
      border-radius: 1.2rem;
      margin-top: 4.8rem;
      &>*{
        font-size: 2.8rem;
        line-height: 160%;
        color: $secondary;
        font-weight: 300;
      }
    }
    &__time{
      height: 8.5rem;
      width: max-content;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $time;
      border-radius: 1.2rem;
      margin-top: 4.8rem;
      & svg{
        margin-right: 1.2rem;
      }
      &>*{
        font-size: 2.8rem;
        line-height: 160%;
        color: $time-text;
        font-weight: 300;
      }
    }
    &__gift{
      &:not(:first-child){
        margin-top: 7.8rem;
      }
      &__title{
        &>*{
          font-weight: 500;
          font-size: 2.8rem;
          line-height: 160%;
          color: #303030;
        }
      }
      &__ico{
        display: flex;
        align-items: center;
        & svg{
          margin-right: 1.2rem;
        }
        & > *{
          font-size: 2.8rem;
          font-weight: 300;
          line-height: 160%;
          color: $dark;
        }
      }
    }
  }
}

@media (max-width: 666px) {
  // GLOABL start //
  .button{
    max-width: 14.1rem;
    height: 4rem;
    border-radius: .4rem;
    & svg, & img{
      display: none;
    }
    &>*, &>*>*{
      font-size: 1.4rem;
      line-height: 19px;
    }
  }
  .section{
    &__promo {
      &__mask {
        display: none;
      }
      &__content{
        min-height: 28.5rem;
      }
      &__title{
        margin-bottom: 1.2rem;
      }
      &__btns{
        flex-direction: column;
      }
    }
    &__title{
      margin-bottom: 2.8rem;
    }
    &__link{
      align-items: center;
      justify-content: center;
      &>*{
        font-size: 1.4rem;
        line-height: 160%;
      }
    }
    &__promo__title, &__title{
      &>* {
        text-align: center;
        font-size: 2rem;
        line-height: 140%;
      }
    }
    &__two-blocks{
      padding-top: 1.6rem;
      padding-bottom: 3rem;
      flex-direction: column-reverse!important;
      &__col{
        width: 100%;
        &:not(:first-child){
          margin-bottom: 3.5rem;
        }
      }
      &__subtitle{
        margin-bottom: 1.2rem;
        &>* {
          font-size: 1.6rem;
          line-height: 160%;
        }
      }
      &__text{
        margin-bottom: 2rem;
        &>*{
          font-size: 1.6rem;
          line-height: 160%;
        }
      }
      &__img{
        margin: 0 auto;
        max-width: 65%;
        &.vertical{
          max-height: 36.7rem;
        }
        &__wrap{
          display: block;
        }
        & img{
          max-height: 36.7rem;
        }
      }
    }
  }
  .input{
    &__text, &__textarea{
      & label{
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #4A4A4A;
        margin-bottom: .4rem;
      }
      & input{
        max-width: 22.3rem;
        height: 4rem;
        padding: 0 .4rem;
        border-radius: .4rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
    &__textarea{
      & textarea{
        max-width: 22.3rem;
        height: 4rem;
        min-height: 7rem;
        padding: .4rem;
        border-radius: .4rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
  .footer{
    padding-top: 7.5rem;
    background-position-y: -11vw;
    margin-top:-11rem;
    &__copyright{
      display: flex;
      justify-content: center;
      &>*{
        font-size: 1.2rem;
        line-height: 160%;
      }
    }
    &__wrapper{
      padding-top: 4rem;
      padding-bottom: 2rem;
      background-color: #dff7e3;
    }
    &_logo{
      display: none;
      &-mobile{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 1.7rem;
        border-top: .1rem solid rgba(87, 87, 87, 0.18);
      }
    }
    &__logo{
      max-width: 10rem;
    }
    &__block{
      max-width: calc(100% / 2 - 2rem);
      &__title{
        margin-bottom: 1.2rem;
        &>*{
          font-size: 1.4rem;
          line-height: 160%;
        }
      }
      &__text{
        &>*{
          font-size: 1.2rem!important;
          line-height: 160%;
        }
      }
    }
    &__socials {
      margin-top: 0;
      &__item {
        width: 4.1rem;
        height: 4.1rem;
      }
    }
    &_text-double{
      flex-direction: row;
      flex-wrap: wrap;
      &__col{
        width: 50%;
        &>*{
          &:first-child{
            margin-right: .5rem;
          }
          &:last-child{
            text-align: left;
          }
        }
      }
    }
    &_time{
      margin-top: 3.2rem;
    }
    &_time, &_time-text{
      width: 100%;
      max-width: 100%;
    }
    &_text-block &__block__text {
      margin: 1.8rem 0;
    }
  }
  // GLOBAL end //

  // INDEX start //
  .home{
    background-image: url(../img/promo/index/home_bg-mobile.png);
    background-position-x: 100%;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    & .section__promo {
      &__content{
        min-height: 38.5rem;
        align-items: flex-start;
      }
      &__inner{
        padding: 3.2rem 0;
      }
      &__title {
        margin-bottom: 1.2rem;

        & > * {
          font-size: 2rem;
          line-height: 140%;
        }
      }
      &__subtitle{
        margin-bottom: 3.2rem;
        &>* {
          font-size: 1.4rem;
          line-height: 160%;
        }
      }
      &__btns{
        flex-direction: column;
        width: 100%;
        &__btn{
          width: 100%;
          margin: 0 0 1.3rem;
          justify-content: center;
          display: flex;
        }
      }
    }

  }
  .about-me{
    .section__two-blocks{
      &__col{
        width: 100%;
        &:last-child{
          display: none;
        }
      }
      &__subtitle, &__text{
        text-align: center;
      }
    }
  }
  .my-services{
    background-position-x: 10%;
    padding-top: 2.3rem;
    &__slider{
      margin-bottom: 4rem;
      &__shadow, &__nav-left, &__nav-right{
        display: none;
      }
      &>div{
        &:not(:last-of-type){
          margin-bottom: 1.2rem;
        }
      }
      &__desc{
        margin-bottom: 2.4rem;
      }
      &__slide{
        &.active{
          & .my-services__slider__text {
            max-height: 60rem;
            margin-bottom: 2rem;
            transition-delay: 0s, 0s;
          }
          & .my-services__slider__title>svg{
            transform: rotate(180deg);
          }
        }
        &__inner{
          padding: 2rem 1.6rem 0;
        }
      }
      &__title{
        & svg, & img {
          display: block;
        }
        &>*{
          font-size: 1.4rem;
          line-height: 2.1rem;
        }
      }
      &__text{
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s linear, margin-bottom .2s linear;
        transition-delay: 0s, .5s;
      }
    }

    &__form{
      padding: 0 16px;
      & form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
      }
      &__subtitle{
        display: none;
      }
      &__row {
        max-width: 22.3rem;
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 1.2rem;
        }
      }
      &__btn{
        width: 100%;
      }
    }
  }
  // INDEX end //

  // ABOUT start //
  .about-promo{
    background-image: url(../img/promo/about/about_bg-mobile.png);
    background-position-x: 100%;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about{
    padding-top: 1.4rem;
    &__elem-2{
      bottom: 100vh;
    }
  }
  .training{
    padding-top: 8rem;
    margin-top: 0;
    background-position-x: 100%;
    background-position-y: -6vw;
    &__inner{
      max-width: 100%;
    }
    &__inner .section__title{
      margin-bottom: 2.4rem;
    }
    &__list {
      padding-left: 1.3rem;
      & li {
        &::before{
          left: -1rem;
        }
        &:not(:last-child) {
          margin-bottom: 3.2rem;
        }
      }
      &__line{
        left: 0;
        transform: translateX(-100%);
      }
      &__head{
        margin-bottom: 0;
      }
      &__desc > :not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &__head, &__desc{
        &>*{
          font-size: 1.4rem;
        }
      }
    }
  }
  // ABOUT end //

  // SERVICES start //
  .services-promo{
    background-image: url(../img/promo/services/services_promo-mobile.png);
    background-position-x: 100%;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .services{
    padding-top: 3.9rem;
    padding-bottom: 26vw !important;
    &__bg{
      &>*{
        width: 200%;
      }
    }
    &__nav{
      overflow-x: scroll;
      justify-content: flex-start;
      margin-bottom: 4rem;
      padding-bottom: 1.6rem;
      &__inner .button {
        height: 3.8rem;
        max-width: unset;
      }
    }
    &__service{
      padding-top: 0;
      padding-bottom: 2.8rem;
      &:not(:last-of-type){
        margin-bottom: 5.5rem;
      }
      &__inner{
        display: flex;
        flex-direction: column-reverse;
      }
      &__content, &__img{
        width: 100%;
      }
      &__img{
        padding-top: 0;
        margin-bottom: 2rem;
        &__inner{
          width: 100%;
          max-height: 42.2rem;
          height: unset;
        }
      }
      &__title{
        margin-bottom: 2rem;
        &>*{
          font-size: 2rem;
        }
      }
      &__subtitle{
        font-size: 1.8rem;
        margin-top: 2.8rem;
      }
      &__text{
        &>*{
          font-size: 1.6rem;
          &:not(:last-of-type) {
            margin-bottom: 1.5rem;
          }
        }
      }
      &__gift{
        &__title{
          margin-bottom: 2rem;
          &>*{
            font-size: 2rem;
          }
        }
        &:not(:first-child){
          margin-top: 4.8rem;
        }
        &__ico>*{
          font-size: 1.6rem;
        }
      }
      &__price, &__time{
        margin-top: 2.8rem;
        height: 5rem;
        &>*{
          font-size: 1.6rem;
        }
      }
    }
  }
  // SERVICES end //

}

@media (max-width: 340px) {
  .footer_text-double__col {
    width: 100%;
  }
}