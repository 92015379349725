@import '_palette.scss';

*,
*::before,
*::after {
	box-sizing: border-box;
}

a{
	color: $link;
	text-decoration: none;
}

.flex {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.--just-space {
	justify-content: space-between;
}

.--just-center {
	justify-content: center;
}

.--just-end {
	justify-content: flex-end;
}

.--align-str {
	align-items: stretch;
}

.--align-center {
	align-items: center;
}

.--align-end {
	align-items: flex-end;
}

.--dir-col {
	flex-direction: column;
}

html {
	font-size: 10px;
}

html,
body {
	min-height: 100%;
	position: relative;
	font-family: 'Poppins';
	font-weight: 400;
	font-style: normal;
	color: $darker;
}

body {
	font-size: 1rem;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1{
	font-style: normal;
	font-weight: 900;
	font-size: 3.6rem;
	line-height: 4.4rem;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

h3{
	font-weight: 900;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

@media (max-width: 1280px) {
	html {
		font-size: 7.8px;
	}
}

@media (max-width: 991px) {
	html {
		font-size: 7px;
	}
}

@media (max-width: 666px) {
	html {
		font-size: 10px;
	}
}